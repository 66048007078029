<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col md="10" />
          <b-col md="2">
            <b-button
              to="sss-kategorileri/ekle"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(title)="data">
              <div>{{ data.item.title }}</div>
            </template>
            <template #cell(status)="data">
              <div class="text-center">
                <FeatherIcon
                  v-if="data.item.status === '1'"
                  class="text-success"
                  icon="CheckIcon"
                />
                <FeatherIcon
                  v-else
                  class="text-danger"
                  icon="XIcon"
                />
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/admin/sss-kategorileri/guncelle/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Güncelle
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'title',
          label: 'SSS KATEGORİLERİ',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'faq_categories.id AS id',
          'faq_categories.title AS title',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['faqCategories/getFaqCategories']
    },
    dataCount() {
      return this.$store.getters['faqCategories/getFaqCategoriesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('faqCategories/faqCategoriesList', this.dataQuery)
    },
  },
}
</script>
